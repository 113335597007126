import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {createUseStyles} from 'react-jss';
import AuthButton from './authButton';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
    bar: {
	textAlign: 'center',
	},
    text: {
	justifyContent:'center',
	display:'flex',
	whiteSpace: 'pre-wrap',
	fontWeight: 'bold',
	},
    authButton: {
	justifyContent:'right',
	verticalAlign: 'middle',
	display: 'flex'
	},
    title: {
	justifyContent:'center',
	verticalAlign: 'middle',
	display: 'flex'
	},
    homeButton: {
	justifyContent:'left',
	verticalAlign: 'middle',
	display: 'flex'
	},
});


export default function CustomBar(props){

    const classes = useStyles();


    return (

	<div className={classes.bar}>
	    <Box sx={{flexGrow:1}}>
		<AppBar position='static'>
		    <Toolbar>
			<Grid className={classes.bottom} spacing={2} container>
			    <Grid className={classes.homeButton} key={0} md={4} item>
				{ props.title === '' ? <div/> :
				<Button color='inherit' onClick={() => props.handleSelection(-1)}>
				    <Typography variant='h5'>
					Home
				    </Typography>
				</Button>}

			    </Grid>
			    <Grid key={1} className={classes.title} md={4} item>
				<Typography variant='h5'>
				    {props.title}	
				</Typography>
			    </Grid>
			    <Grid key={2} className={classes.authButton} md={4} item>
				<AuthButton handleAuth={props.handleAuth}/>
			    </Grid>
			</Grid>
		    </Toolbar>
		</AppBar>
	    </Box>
	</div>
	)
}
